<template>
    <div class="cs-interactive-contact-interface cs-block" :class="`${styleClass}${hasBg}`">
        <div class="cs-block-base">
            <div class="container">
                <CSMap
                    :zoom="data.zoom"
                    :center="coordinates"
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution:='&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                />

                <div class="map-content">
                    <h2 class="cs-title" v-if="data.title">
						<ColorSplit :data="data.title" />
					</h2>
                    <h3 v-if="data.subTitle" class="cs-sub-title">{{ data.subTitle }}</h3>
                    <span v-if="data.text" class="cs-text" v-html="data.text"></span>

                    <div class="block-buttons" v-if="data.buttons && ((typeof data.buttons == 'object' && Object.keys(data.buttons).length) || (Array.isArray(data.buttons) && data.buttons.length))">
                        <Buttons :data="data.buttons"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {computed} from '../libs/common-fn';

export default {
	name: "Contact",
	components: {
		CSMap: () => import("../components/CSMap.vue"),
        Buttons: () => import("../components/CSUtilsButtons.vue"),
        ColorSplit: () => import("../components/CSUtilsColorSplit.vue"),
	},
	props: {
		data: {
			type: Object,
			default: () => {},
		},
	},
	methods: {},
	computed: {
        ...computed('InteractiveContactInterface'),
        hasBg() {
            if (this.data.style == 'color') return ' has-background';

            return '';
        }
    }
}
</script>
<style scoped lang="scss">
@import "../styles/main.scss";
.cs-block.cs-interactive-contact-interface {
    ::v-deep {
        .leaflet-container {
            height: 500px !important;
            border-radius: 15px;
        }
        
        .map {
            margin-bottom: 30px;
        }
    }
    
    .map-content {
        text-align: center;
    }
    &.cs-style- {
        &color {
            @include cs-block-color;
            .cs-sub-title{
                color:$sub_title_color;
            }
            *, ::v-deep *{
                color:white;
            }
        }

        &light {
            @include cs-block-light-image;
            .cs-sub-title{
                color: $sub_title_color_light;
            }
        }

        &dark {
            @include cs-block-dark;
            .cs-sub-title{
                color: $sub_title_color_dark;
            }
        }
    }
}
</style>
